import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BASEURL, headers } from '../../../Constant';
import { Link } from "react-router-dom";
import "./WishcardsView.scss"
import { Spinner } from 'react-bootstrap';


const Wishcards = () => {
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [url, setUrl] = useState("http://159.223.73.77/");
  const [loading, setLoading] = useState(true);

  const wishlistProperty = () => {
    setLoading(true); // Set loading to true

    axios.post(BASEURL + "/user/api/v1/gmt/whishlist/property?limit=10&page=1&sort=propertyName", "", { headers })
      .then((resp) => {
        console.log(resp.data.data[0].propertyData, "Tushar REss");
        setPropertyDetails(resp.data.data[0].propertyData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    wishlistProperty();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        </div>
      ) : (
        <>
          {propertyDetails.map((item) => (
            <div className="wishlist-card primary-bg-sec d-flex flex-column flex-grow-1" key={item._id}>
              <div className='wishlist-card-img'>
                <img src={url + item.mainImagePath[0].split("/public")[1]} alt="" srcSet="" />
              </div>
              <div className='wishlist-card-info d-flex flex-column gap-2'>
                <h3>{item.propertyName}</h3>
                <div className="d-flex justify-content-between">
                  <p>{item.propertyDescription}</p>
                  <p className='remove-link pointer'>Remove</p>

                  <Link href="#" className="btn btn-primary shadow btn-xs sharp m-1" to={"/Wishcards-View/" + item._id}>
                    <p className='ViewItem'>view</p>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default Wishcards;

import React, { useEffect, useState } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import PropertyImages from './components/PropertyImages/PropertyImages';
import PropertyPrice from './components/PropertyPrice/PropertyPrice';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import { BASEURL, headers } from '../../../../Constant';
import { useParams } from 'react-router-dom';
import moment from 'moment';



const Details = (props) => {
    const params = useParams();
    const [url, seturl] = useState("http://159.223.73.77/")
    const [amenities, setamenities] = useState([])

    const getallamenities = () => {
        axios.get(`${BASEURL}/user/api/v1/propertyamenities/${params.id}/get`, { headers }).then((resp) => {
            setamenities(resp.data.data[0].amenities);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getallamenities();
    }, [])

   


    return (
        <>
            <div className="section gap-3">
                {/* heading Section */}
                <div className="d-flex justify-content-between primary-text-color flex-wrap">
                    <div className='d-flex flex-column gap-1'>
                        <h3 className='mediam-heading'>{props.property ? props.property.propertyName : ""}</h3>
                        <p className='small-bold-text'>
                            {props.property.city ? props.property.city.city : ""},&nbsp;
                            {props.property.state ? props.property.state.state : ""},&nbsp;
                            {props.property ? props.property.country : ""}
                        </p>
                    </div>

                    <div className="d-flex gap-4">
                        <p className='small-bold-text center gap-1' style={{ fontSize: '14px' }}>
                            <ShareIcon style={{ width: '14px', }} /> Share</p>
                        <p className='small-bold-text center gap-1' style={{ fontSize: '14px' }}>
                            <FavoriteBorderIcon style={{ width: '14px' }} />Save</p>
                    </div>
                </div>





                <PropertyImages property={props.property} />

                <div className="detail-description d-flex  justify-content-between flex-wrap gap-3">
                    <div className=' d-flex flex-column' style={{ maxWidth: '635px', gap: '13px' }}>
                        <div><p className='about-property'>ABOUT THIS PROPERTY</p></div>
                        <div className="card-detail flex-grow-1 d-flex flex-column">

                            <div className='card-info  crad-border d-flex flex-column gap-3'>
                                <h3>{props.property.propertyDescription ? props.property.propertyDescription : ""}</h3>

                            </div>


                            <div className='facility'>
                                {/* <div className="d-flex flex-wrap justify-content-evenly justify-content-md-between">
                                    {amenities.length > 0 ? amenities.map((a) => (
                                        <><img src={url + a.amenityImagePath[0].split("/public")[1]} alt="" style={{ width: "70px" }} srcSet="" /><span>{a.amenityValue == true ? a.amenityValue : <CheckIcon />} {a.amenityName}</span></>
                                    )) : ""}
                                </div> */}
                                <div><p className='about-property pb-4'>WHAT THIS PLACE OFFERS</p></div>

                                {/* <div className="d-flex flex-wrap  gap-2 ">
                                    {amenities.length > 0 ? amenities.map((a) => (
                                        <p><img src={url + a.amenityImagePath[0].split("/public")[1]} alt="" style={{ width: "35px", paddingRight: '5px' }} srcSet="" /><span>{a.amenityValue == true ? a.amenityValue : <CheckIcon />} {a.amenityName}</span></p>
                                    )) : ""}
                                    <p><CheckIcon />8 Room</p>
                                    <p><CheckIcon />8 Bath</p>
                                    <p><CheckIcon />16 People</p>
                                    <p><CheckIcon />650 m2</p>
                                </div> */}
                                {/* <div className="d-flex flex-wrap mt-3 gap-2">
                                    <p><CheckIcon />Swimming Pool</p>
                                    <p><CheckIcon />Air Conditioning</p>
                                    <p><CheckIcon />Free Wifi</p>
                                    <p><CheckIcon />Daily Breakfast</p>
                                    <p><CheckIcon />Car Parking</p>
                                    <p><CheckIcon />Garage</p>
                                </div> */}
                            </div>

                            <div>
                                <div className="d-flex flex-wrap mt-3 gap-3">
                                    <p><CheckIcon />Address Line 1: {props.property ? props.property.addressLine1 : ""} </p>
                                    <p><CheckIcon />Address Line 2: {props.property ? props.property.addressLine2 : ""} </p>
                                    <p><CheckIcon />Discounted Price: {props.property ? props.property.discountedPrice : ""} </p>
                                    <p><CheckIcon />latitude: {props.property ? props.property.latitude : ""} </p>
                                    <p><CheckIcon />longitude: {props.property ? props.property.longitude : ""} </p>
                                    {/* <p><CheckIcon />Property Description: {props.property ? props.property.propertyDescription : ""} </p> */}
                                    {/* <p><CheckIcon />Property Name: {props.property ? props.property.propertyDescription : ""} </p>
                                    <p><CheckIcon />Property Per Night: {props.property ? props.property.propertyType[0].propertyTypeName : ""} </p>
                                    <p><CheckIcon />Property Per Night: {props.property ? props.property.videoUrl : ""} </p>
                                    <p><CheckIcon />Min Follower Count: {props.property ? props.property.compaing.MinFollowerCount : ""} </p>
                                    <p><CheckIcon />Allow Per Day Booking: {props.property ? props.property.compaing.allowPerDayBooking : ""} </p>
                                    <p><CheckIcon />Start Date: {props.property ? moment(props.property.compaing.startDate).format('DD-MM-YYYY') : ""} </p>
                                    <p><CheckIcon />End Date: {props.property ? moment(props.property.compaing.endDate).format('DD-MM-YYYY') : ""} </p>
                                    <p><CheckIcon />No Of Booking Night: {props.property ? props.property.compaing.noOfBookingNight : ""} </p>
                                    <p><CheckIcon />No Of Lives: {props.property ? props.property.compaing.noOfLives : ""} </p>
                                    <p><CheckIcon />No Of Posts: {props.property ? props.property.compaing.noOfPosts : ""} </p>
                                    <p><CheckIcon />No Of Reels: {props.property ? props.property.compaing.noOfReels : ""} </p>
                                    <p><CheckIcon />No Of Stories: {props.property ? props.property.compaing.noOfStories : ""} </p>
                                    <p><CheckIcon />Total Camping Booking Allowed: {props.property ? props.property.compaing.totalCampingBookingAllowed : ""} </p> */}
                                </div>
                            </div>



                            {/* <div className='card-btn d-flex gap-4'>
                                <button className="btn dark-blue">
                                    <p>Stay per night</p>
                                    <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight : ""}</p>
                                </button>
                                <button className="btn dark-btn">
                                    <p>Own a share</p>
                                    <p>₹{props.property.perSharePrice ? props.property.perSharePrice : ""}</p>
                                </button>

                            </div> */}
                        </div>
                    </div>

                    <div className=' d-flex flex-column flex-grow-1' style={{ maxWidth: '525px', gap: '13px' }}>
                        <div><p className='about-property'>STAY HERE</p></div>
                        <PropertyPrice property={props.property} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details

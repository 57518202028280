import React from 'react'

const SimpleThings = () => {
    return (
        <>
            <div className="secondary-bg-sec section  gap-2  gap-md-5 justify-content-center" style={{ minHeight:'518px'}} >
                <div className=' d-flex justify-content-evenly justify-content-md-between align-items-center flex-wrap gap-4'>

                    <div className='simple-think d-flex flex-column align-items-center align-items-md-start gap-4 flex-grow-1' style={{ maxWidth: '500px' }}>
                        <h1 className='Sec-heading '>We make things simple.</h1>
                        <p className='small-text' style={{ lineHeight:'22px' }}>Lorem fff ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</p>
                        <button type='button' className="btn primary-bg-sec">See Our Homes</button>
                    </div>

                    <div className='video-thumbnail flex-grow-1' style={{ maxWidth:'640px' }}>
                    <iframe width="100%" height="359" src="https://www.youtube.com/embed/zumJJUL_ruM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SimpleThings

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';


import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios'
import { BASEURL, headers } from '../../Constant'
import './CheckOutPage.scss'
import CheckIcon from '@mui/icons-material/Check';
import StripeCheckout from 'react-stripe-checkout';
// import moment from 'moment';
// import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';





const CheckOutPage = () => {


    console.log(new Date())
    const [state, setState] = useState([
        // {
        //     startDate: new Date(),
        //     endDate: addDays(new Date(), 6),
        //     key: 'selection'
        // },
        {
            startDate: new Date("2023-06-25"),
            endDate: addDays(new Date(), 6),
            key: 'selection'
        },
        // {
        //     startDate: new Date("2023-07-21"),
        //     endDate: addDays(new Date("2023-07-27"),0),
        //     key: 'selection'
        // },

        // {
        //     startDate: new Date("2023-07-1"),
        //     endDate: addDays(new Date("2023-07-10"),0),
        //     key: 'selection'
        // }


    ]);



    const [propertycheckout, setpropertycheckout] = useState([])
    const [city, setcity] = useState([])
    const [compaing, setcompaing] = useState([])
    const [country, setcountry] = useState([])
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [disabledDates, setDisabledDates] = useState([]);
    const [firstname, setfirstname] = useState([]);
    const [lastname, setlastname] = useState([]);
    const [email, setemail] = useState([]);
    const [propertyPerNight, setpropertyPerNight] = useState([])
    const [mainImagePath, setmainImagePath] = useState([])
    const [url, seturl] = useState("http://159.223.73.77/")
    const [newtoken, setnewtoken] = useState("")
    const [propertyId, setpropertyId] = useState([])
    const [campaingId, setcampaingId] = useState([])
    const [isRequested, setisRequested] = useState([])
    const [requestId, setrequestId] = useState([])
    const [mydate, setmydate] = useState([])
    const [selectedDate, setSelectedDate] = useState({ startDate: "", endDate: "" });
    const [discountOfBookings, setdiscountOfBookings] = useState([])

    useEffect(() => {
        // const token = localStorage.getItem("token");
        let urlString = new URL(window.location.href)
        console.log(urlString);
        // let paramString = urlString.split('?')[1];
        // console.log(paramString);

        let queryString = new URLSearchParams(urlString.search);

        console.log(queryString, "wuerrrrr")

        let obj = {};
        for (let pair of queryString.entries()) {

            if (pair[0] === "id") {
                // setid(pair[1])
                obj.id = pair[1];
            }
            if (pair[0] === "token") {
                obj.token = pair[1];
                // settoken(pair[1])
            }

            console.log("Key is:" + pair[0]);

            console.log("Value is:" + pair[1]);
            console.log(obj.token, "iiiii");
            setnewtoken(obj.token);
        }


        const fetchData = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get(`${BASEURL}/user/api/v1/gmt/checkout/property/${obj.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        //    Authorization: `Bearer ${obj.token}`,

                    },
                });
                setpropertycheckout(response.data.data[0])
                setcity(response.data.data[0].city[0])
                setcompaing(response.data.data[0].compaing)
                setcountry(response.data.data[0])

                setStartDate(response.data.data[0].compaing)
                setEndDate(response.data.data[0].compaing)
                setfirstname(response.data.data[0].isRequested[0])
                setlastname(response.data.data[0].isRequested[0])
                setemail(response.data.data[0].isRequested[0])
                setpropertyPerNight(response.data.data[0])

                setmydate(response.data.data[0].compaing)
                // console.log(response.data.data[0].compaing.MinFollowerCount, "/////")
                setrequestId(response.data.data[0].isRequesteduser[0])
                setisRequested(response.data.data[0].isRequested[0])
                setcampaingId(response.data.data[0].compaing)
                setpropertyId(response.data.data[0].compaing)
                setmainImagePath(response.data.data[0])
                const { startDate, endDate } = response.data.data[0].compaing;
                console.log(response.data.data[0].compaing, "/////")
                setdiscountOfBookings(response.data.data[0].compaing)

                // const disabled = getDisabledDates(startDate, endDate);
                // setDisabledDates(disabled);

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
    }, [])







    // const handleToken = async (token) => {
    //     try {
    //         const response = await axios.post(`${BASEURL}/user/api/v1/payment/`,
    //         {
    //             token: token.id,
    //             amount: propertyPerNight.propertyPerNight * 100,
    //             currency: 'USD',
    //         },
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${newtoken}`,
    //             },
    //         });

    //         console.log(response.data);
    //         alert('payment created successfully!');
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };


    // const handleToken = async (token) => {
    //     try {
    //         const response1 = await axios.post(`${BASEURL}/user/api/v1/payment/`, {
    //             token: token.id,
    //             amount: propertyPerNight.propertyPerNight * 100,
    //             currency: 'USD',
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${newtoken}`,
    //             },
    //         });

    //         console.log(response1.data);
    //         alert('Payment created successfully!');

    //         if (response1.status === 200) {
    //             const response2 = await axios.post(`${BASEURL}/user/api/v1/booking-camping`, {
    //                 propertyId: propertyId.propertyId,
    //                 campaingId:campaingId._id,
    //                 userId:isRequested._id,
    //                 requestId:requestId._id,
    //                 bookingAmount:propertyPerNight.propertyPerNight,
    //                 bookingDate:{
    //                     startDate:mydate.startDate,
    //                     endDate:mydate.endDate
    //                 },
    //                 transactionDetails:{ }
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${newtoken}`,
    //                 },
    //             });

    //             console.log(response2.data);
    //             alert('Booking created successfully!');
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const booking = async () => {
        const startDateFormatted = moment(mydate.startDate, 'DD-MM-YY').format('YYYY-MM-DD');
        const endDateFormatted = moment(mydate.endDate, 'DD-MM-YY').format('YYYY-MM-DD');

        try {
            const response2 = await axios.post(`${BASEURL}/user/api/v1/booking-camping`, {
                propertyId: propertyId.propertyId,
                campaingId: campaingId._id,
                userId: isRequested._id,
                requestId: requestId._id,
                bookingAmount: propertyPerNight.propertyPerNight,
                bookingDate: selectedDate,
                transactionDetails: {}
            }, {
                headers: {
                    Authorization: `Bearer ${newtoken}`,
                },
            });

            console.log(response2.data);
            alert('Booking created successfully!');
        } catch (error) {
            console.error('Error creating booking:', error);
            alert('Please Select Date');
        }
    };


    const moment = require('moment');
    const handleToken = async (token) => {
        try {
            const response1 = await axios.post(`${BASEURL}/user/api/v1/payment/`, {
                token: token.id,
                amount: propertyPerNight.propertyPerNight * 100,
                currency: 'USD',
            }, {
                headers: {
                    Authorization: `Bearer ${newtoken}`,
                },
            });

            console.log(response1.data);
            alert('Payment created successfully!');

            if (response1.status === 200) {
                // const startDateFormatted = moment(mydate.startDate, 'DD-MM-YY').format('YYYY-MM-DD');
                // const endDateFormatted = moment(mydate.endDate, 'DD-MM-YY').format('YYYY-MM-DD');

                // const response2 = await axios.post(`${BASEURL}/user/api/v1/booking-camping`, {
                //     propertyId: propertyId.propertyId,
                //     campaingId: campaingId._id,
                //     userId: isRequested._id,
                //     requestId: requestId._id,
                //     bookingAmount: propertyPerNight.propertyPerNight,
                //     bookingDate: selectedDate,
                //     transactionDetails: {}
                // }, {
                //     headers: {
                //         Authorization: `Bearer ${newtoken}`,
                //     },
                // });

                // console.log(response2.data);
                // alert('Booking created successfully!');
                booking();
            }
        } catch (error) {
            console.error(error);
        }
    };

    //apply condition if {totalprice} is zero i clicked to Check Out button direct form submitted payment option is not show

    const changeBookingDate = (date) => {
        console.log(date.selection.startDate, "datedate");
        console.log(date.selection.endDate, "datedate");
        let dates = { ...selectedDate };
        dates.startDate = date.selection.startDate
        dates.endDate = date.selection.endDate
        setSelectedDate(dates);
    }

    const today = dayjs();
    const defaultStartDate = dayjs(startDate.startDate);
    const defaultEndDate = dayjs(startDate.endDate);

    const shouldDisableDate = (date) => {
        return (
            date.isBefore(today, 'day') ||
            date.isBefore(defaultStartDate, 'day') ||
            date.isAfter(defaultEndDate, 'day')
        );
    };

    let price = (propertyPerNight.propertyPerNight) * (discountOfBookings.discountOfBookings) / (100)
    console.log(price, "price")

    let totalprice = (propertyPerNight.propertyPerNight) - price
    console.log(totalprice, "?????????")


    return (
        <>
            <div class='layout-size'>
                <div class="section primary-bg-sec faq-main-page" style={{ borderRadius: "15px" }}>
                    <div class="container mt-4">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-12">
                                        <h4>Property Detail</h4>
                                    </div>
                                    <div class="col-md-6 text-center pt-2">
                                        {mainImagePath && mainImagePath.mainImagePath && mainImagePath.mainImagePath[0] ? (
                                            <img
                                                src={url + mainImagePath.mainImagePath[0].split('/public')[1]}
                                                alt=""
                                                class="img-fluid"
                                                style={{ borderRadius: "11px" }}
                                            />
                                        ) : (
                                            <div></div>
                                        )}

                                        {/* <img src="https://static.indiaproperty.com/property-images/237/7016237/t3_7016237_56229.jpeg" alt="" class="img-fluid" style={{ borderRadius: "11px" }} /> */}
                                    </div>
                                    <div class="col-md-6 pt-2 ">
                                        <p>Property Name: {propertycheckout.propertyName}</p>
                                        <p>Property Address: {propertycheckout.addressLine1}, {propertycheckout.addressLine2}, {city.city}, {country.country} </p>
                                    </div>
                                </div>
                                <br />
                                <div class="col-12">
                                    <h4>User Details</h4>
                                    <p>First Name: {firstname.firstName}</p>
                                    <p>Last Name: {firstname.lastName}</p>
                                    <p>Email Address: {email.email}</p>
                                </div>

                                <div className="d-flex flex-wrap pt-3">

                                    <p>{compaing.isFreeMeal ? <CheckIcon /> : null} Free Meal </p>
                                    <p>{compaing.isFreeTrevell ? <CheckIcon /> : null} Free Trevel </p>

                                </div>
                            </div>
                            <div class="col-md-4 pt-4">
                                <div class="row">
                                    <div class="col-12">
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <StaticDateRangePicker
                                                defaultValue={[defaultStartDate, defaultEndDate]}
                                                onChange={(e, date) => changeBookingDate(e, date)}
                                                renderInput={(startProps, endProps) => (
                                                    <React.Fragment>
                                                        <input {...startProps.inputProps} />
                                                        <input {...endProps.inputProps} />
                                                    </React.Fragment>
                                                )}
                                                shouldDisableDate={shouldDisableDate}
                                                sx={{
                                                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                                                        alignItems: 'center',
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider> */}
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={(date) => changeBookingDate(date)}
                                            moveRangeOnFirstSelection={false}
                                            ranges={state}
                                        />

                                    </div>
                                    <div class="col-12 pt-4">
                                        <div class='pricing-tab d-flex flex-column gap-3'>
                                            <div class='check-in-out gust-no gap-2 d-flex flex-column'>
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <p class='small-bold-text-line d-flex'>Total Amount: {totalprice} </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {
                                            totalprice !== 0
                                                ? <StripeCheckout
                                                    token={handleToken}
                                                    stripeKey="pk_test_51MEshoG9C7zvsUkgbfnl3iqC4E4YhgDL79sHxfjT5fqwn6hQObsslDcwTiCbeqzLzdsrCITJ9C34eKV4csXMH1kD00N27neL5D"
                                                    amount={totalprice * 100}
                                                    currency="USD"
                                                    name="My Store"
                                                    description="Purchase"
                                                >
                                                    <button class="btn blue-btn ms-auto d-block">Check Out</button>
                                                </StripeCheckout> :
                                                <button class="btn blue-btn ms-auto d-block" onClick={booking} >Check Out</button>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CheckOutPage





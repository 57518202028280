import React, { useState } from 'react'
import "./topsec.scss"
import { Search } from '@mui/icons-material'
import Person from "./image/Person.svg"
import ExploreSearch from './ExploreSearch/ExploreSearch'
import Smallsearch from './ExploreSearch/Smallsearch'


const Topsec = () => {
    const [search, setsearch] = useState(false)
    let filters = ["Quick filter", "Quick filter", "Quick filter", "Quick filter", "Quick filter", "Quick filter", "Quick filter", "Quick filter",]
    return (
        <>
            <div className="explore-top-section d-flex flex-column justify-content-center align-items-center gap-3 py-3">
                <h1 className=' text-center'>Luxury homes in top locations</h1>

                {search ? <ExploreSearch /> : <Smallsearch setsearch={setsearch} />}

                <div className="d-flex filter-bar w-100 justify-content-evenly flex-wrap gap-2">
{/* 
                    {filters.map((filter, i) =>
                        <div className=' d-flex align-items-center gap-1' key={i}>
                            <div className='filter-img-icon'><img src={Person} alt="" srcSet="" /></div>
                            <p className='filter-bar-text'>{filter}</p>
                        </div>
                    )} */}

                </div>
            </div>
        </>
    )
}

export default Topsec

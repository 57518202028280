import React from 'react'
import "./learnmore.scss"
import Key from "./images/Key.svg"

const LearnMore = () => {

    let services = [
        { head: 'The intimate feel of a private property.', titile: 'The comfort and quietness of a stunning private house with complete freedom and a (genuine) feeling of exclusivity. Your are here, at home.' },
        { head: 'Services worthy of the (very) best hotels.', titile: 'Included in all our stays: daily housekeeping, a delicious breakfast served every morning… Exclusive and bespoke experience : small touches, attention to detail and surprises.' },
        { head: 'Your dedicated House manager', titile: 'Concierge service, VIP welcome, assitance during the stay... our House Manager is available before you arrive and throughout your stay to make it unforgettable' },
        { head: 'Your dream house', titile: 'For the perfect getaway and beyond all your expectations : all you need (and dream) to WORK, to BE IN SHAPE (sport), to BE WELL (wellness), to PLAY and for your KIDS. Focus on you, we’ve thought of everything else...' },
    ]
    return (
        <>
            <div className=' section learn-more d-flex flex-column '>
                <div><p className='middle-text text-center'>Learn More &#8595;</p></div>
                <div className="learn-more-second-sec flex-grow-1 align-items-center d-flex">

                    <div className=' flex-grow-1 d-flex justify-content-evenly justify-content-md-between align-items-start flex-wrap gap-4'>


                        <div className='learn-more-left d-flex flex-column gap-4' style={{ maxWidth: '500px' }}>
                            <h1>A home away from home, <i>but better.</i></h1>
                            <p>Iconic House combines the best of the luxury hotel experience
                                with vacation rental advantages.</p>
                            <button className="btn">Get Started</button>
                        </div>

                        <div className="learn-more-right d-flex flex-column" style={{ maxWidth: '511px' }}>

                            {services?.map((service, i) =>
                                // {/* single Section */}
                                <div className=' d-flex flex-column gap-3' key={i}>
                                    <div className='after-border position-relative d-flex align-items-center'>
                                        {/* <img src={Key} alt="" srcSet="" style={{ width:'44px'}} /> */}
                                        <h3>{service.head}</h3>
                                    </div>
                                    <div className=''>
                                        <p className=' ' >{service.titile}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default LearnMore

import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from './component/Navbar/Navbar';
import Footer from './component/Footer/Footer';
import Routing from './Routing';

function App() {
  const [token, settoken] = useState(localStorage.getItem('usertoken'));
  return (
    <>
      <Navbar />
      <Routing />
      {/* <Footer /> */}
    </>
  );
}

export default App;

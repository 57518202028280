import React from 'react'
import "./herosec.scss"

const Herosec = (props) => {
    return (
        <>
            <div className="herosec d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundImage: `url(${props.bgimg})` }} >
                <div className='heto-text d-flex flex-column'>
                    <h1 className='text-center'
                     style={{fontSize:props.textSmall? "100px":"" , lineHeight:props.textSmall? "90px":"" }}
                     >{props.heading}</h1>
                    <p className='text-center'>{props.title}</p>
                </div>
            </div>
        </>
    )
}

export default Herosec

import React from 'react'
import "./Testimonials.scss"
import img from "./image/img.jpg"
import profImg from "./image/profImg.jpg"

export let Cards = [
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
    { img: img, name: 'Mark M.', designation: 'Co-Owner', message: 'Remarkable service. We never thought we could ever own a vacation home like this! Thank you.' },
    { img: profImg, name: 'Susan H.', designation: 'Co-Owner', message: 'I just love the quality of life we can enjoy now. Working from home has become a dream.' },
]
const Testimonials = () => {

    return (
        <>
            <div className="testmonial position-relative secondary-bg-sec section  overflow-hidden justify-content-center">
                {/* <div className="shadow-layer shadow-layer-left  w-100  position-absolute">feff</div> */}
                {/* <div className="shadow-layer shadow-layer-right w-100  position-absolute">feff</div> */}


                <h1 className='Secondary-heading  text-center text-md-start' >What co-owners are saying.</h1>
                <div className=' position-relative'>
                    <div className="shadow-layer shadow-layer-left position-absolute"></div>
                    <div className="shadow-layer shadow-layer-right   position-absolute"></div>
                    <div className="hide-scrollbar card-container d-flex position-relative">
                        {/* <div className="shadow-layer shadow-layer-right w-100  position-absolute">feff</div> */}
                        {Cards?.map((card, i) =>
                            <div className='testmonial-card d-flex flex-column gap-2 ' key={i}>
                                <div className="d-flex gap-3 align-items-center">
                                    <div className="prof-img">
                                        <img src={card.img} alt="dd" srcSet="" />
                                    </div>
                                    <div>
                                        <p><b>{card.name}</b></p>
                                        <p>{card.designation}</p>
                                    </div>
                                </div>
                                <p className="repeated-text">{card.message}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonials

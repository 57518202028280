import React, { useEffect, useState } from 'react'
import "./properties.scss"
import Slider from "react-slick";

// Icons 
import CheckIcon from '@mui/icons-material/Check';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import Baths from "./image/Baths.svg"
import Gallery from "./image/Gallery.svg"
import People from "./image/People.svg"
import Measure from "./image/Measure.svg"
import PropertyInfo from './components/PropertyInfo';
import axios from 'axios';
import { BASEURL, headers } from '../../../../Constant';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const OurProperties = () => {
  const [properties, setproperties] = useState([]);
  const [url, seturl] = useState("http://159.223.73.77/")
  const [showProperties, setshowProperties] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  useEffect(() => {
    axios.post(BASEURL + "/user/api/v1/propertylist?limit=4&page=1&sort=propertyName", "", { headers }).then((resp) => {
      setproperties(resp.data.data[0].propertyData);
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  return (
    <>
      <div className='properties section '>
        {/* Section heading */}
        <h1 className='Sec-heading' >Our properties.</h1>
        <Slider {...settings}>
          {properties.length > 0 ?
            properties.map((property, i) => (
              <div key={i}>
                <div className=' d-flex justify-content-center align-self-center mx-auto' style={{ maxWidth: '1093px' }}>
                  <div className='property-slide d-flex  flex-wrap'>
                    <div className="slide-img flex-grow-1 position-relative">
                      <div className=' d-flex flex-column slide-img-bottom position-absolute '>
                        <div className=" save-property "><FavoriteBorderIcon /></div>
                        {/* <div className=" show-property "><img src={Gallery} alt=""  /></div> */}
                        <div className=" show-property "><ContentCopyIcon /></div>
                      </div>
                      <img src={url+property.mainImagePath[0].split("/public")[1]} alt="" srcSet="" />
                    </div>
                    <div className="card-detail flex-grow-1 d-flex flex-column">
                      <div className='card-head crad-border'>
                        <h3>{property.propertyName}</h3>
                        <p>{property.city[0].city}, {property.state[0].state}, {property.country}</p>
                      </div>
                      <div className='card-info  crad-border'>
                        <h3>{property.propertyDescription}</h3>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                      </div>
                      <div className='facility crad-border' style={{ lineHeight:'22px' }}>
                        <div className="d-flex flex-wrap justify-content-evenly justify-content-md-between">
                          <p><SingleBedIcon />8 Rooms</p>
                          <p>
                            {/* <img src={Baths} className="Icon-img" alt="" srcSet="" /> */}
                            8 Rooms
                          </p>
                          <p><img src={People} className="Icon-img" alt="" srcSet="" />16 People</p>
                          <p><img src={Measure} className="Icon-img" alt="" srcSet="" />8 Rooms</p>
                        </div>
                        <div className="d-flex flex-wrap justify-content-evenly justify-content-md-between">
                          <p><CheckIcon />Swimming Pool</p>
                          <p><CheckIcon />Air Conditioning</p>
                          <p><CheckIcon />Free Wifi</p>
                          <p><CheckIcon />Daily Breakfast</p>
                          <p><CheckIcon />Car Parking</p>
                          <p><CheckIcon />Garage</p>
                        </div>
                      </div>
                      <div className='card-btn d-flex gap-4'>
                        <button className="btn dark-blue">
                          <p>Stay per night</p>
                          <p className='price-btn mt-2'>₹{property.propertyPerNight}</p>
                        </button>
                        <button className="btn dark-btn">
                          <p>Own a share</p>
                          <p className='price-btn mt-2' >₹{property.perSharePrice}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)) : ""}
        </Slider>
      </div>
    </>
  )
}
export default OurProperties

import React from 'react'
import { Search } from '@mui/icons-material'

const Smallsearch = (props) => {
    return (
        <>
            <div className="search-box d-flex justify-content-between gap-2 align-items-center fw-bolder">
                <div className='d-flex justify-content-between flex-grow-1 text-center'>
                    <div className='flex-grow-1 divider-border'><p className='explore-search-text'>Where</p></div>
                    <div className='flex-grow-1 divider-border'><p className='explore-search-text'>When</p></div>
                    <div className='flex-grow-1'><p className='explore-search-text'>Who</p></div>
                </div>
                <button className='search-icon pointer btn d-flex justify-content-center align-items-center'
                onClick={()=>{props.setsearch(true)}}
                ><Search /></button>
            </div>
        </>
    )
}

export default Smallsearch

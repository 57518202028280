import React from 'react'
import Howstart from '../../../Homepage/components/HowStarted/Howstart'

const HowItWorks = () => {
    return (
        <>
            <div className=' section learn-more gap-1 gap-md-5 justify-content-center' style={{ paddingLeft:'0px'}}>
                <div><p className='middle-text text-center'>How it Works &#8595;</p></div>
                <div>
                    <Howstart heading='How rentals work.' primary = {true} gap={60}/>
                    <Howstart heading='How ownership works.' primary = {true} gap={60}/>
                </div>
            </div>
        </>
    )
}

export default HowItWorks

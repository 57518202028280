import React, { useState } from 'react'
import Faq from './components/FAQ/Faq'
import Featured from './components/Featured/Featured'
import Herosec from './components/Herosec/Herosec'
import Howstart from './components/HowStarted/Howstart'
import LearnMore from './components/LearnMore/LearnMore'
import OurProperties from './components/Properties/OurProperties'
import SaveEarn from './components/Save&earn/SaveEarn'
import Testimonials from './components/Testimonials/Testimonials'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Stepmodal from './stepmodal/Stepmodal';
import herobg from "./images/herobg.jpg"
import Footer from '../Footer/Footer'





const Homepage = () => {
    const [modalShow, setModalShow] = useState(false);
    const [stepmodalshow, setStepmodalshow] = useState(false);
    return (
        <>
            <div className='position-relative'>
                <Herosec
                    heading="Pursue your passion for travel, often."
                    title="Tap in to a network of vacation properties so you can travel, often."
                    bgimg={herobg}
                />
                <div className='layout-size above-header'>
                    <LearnMore />
                    <div className='home-howstart'>
                        <Howstart heading='How to get started.' primary={false} gap={99} setStepmodalshow={setStepmodalshow} />
                    </div>
                    <OurProperties />
                    <Testimonials />
                    <SaveEarn />
                    <Faq />
                    <Featured />
                    <Footer/>
                </div>
            </div>
            
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Stepmodal stepmodalshow={stepmodalshow} setStepmodalshow={setStepmodalshow}/>
        </>
    )
}

export default Homepage

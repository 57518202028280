import React from 'react'
import "./Feature.scss"
import slogo from "./images/slogo.png"

const Featured = () => {

  // let parte
  return (
    <>
      <div className="section feature-sec d-flex flex-column gap-4">
        <h3 className='feature-heading'>As Featured In</h3>
        <div className=" feature-conatiner d-flex overflow-scroll hide-scrollbar">
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
            <div className='feature-logo'><img src={slogo} alt=""  /></div>
        </div>
      </div>
    </>
  )
}

export default Featured

import React from 'react'
import "./ExploreSearch.scss"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Search } from '@mui/icons-material';

const ExploreSearch = () => {
    return (
        <>
            <div className="primary-text-color   repeated-text explore-search d-flex flex-column align-items-center">
                <Tabs
                    defaultActiveKey="Rental"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Rental" title="Rental">
                        <div className="search-box w-auto d-flex justify-content-between gap-2 align-items-center fw-bolder">
                            <div className='filter-search d-flex justify-content-between flex-grow-1'>
                                <div className='flex-grow-1 divider-border'>
                                    <p className='explore-search-text' >Where</p>
                                    {/* <p className='search-box-text-lite'>Search destinations</p> */}
                                    <input type="text" name="" id="" placeholder='Search destinations' />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check in</p>
                                    <input type="text" placeholder="Add date"
                                        // onChange={(e) => console.log(e.target.value)}
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check out</p>
                                    <input type="text" placeholder="Add date"
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                {/* <div className='flex-grow-1'>
                                    <p className='explore-search-text' >Who</p>
                                    <input type="text" name="" id="" placeholder='Add guests' />
                                </div> */}
                            </div>
                            <button className='btn repeate-btn dark-btn'>Search</button>
                        </div>
                    </Tab>

                    <Tab eventKey="Ownership" title="Ownership">
                        <div className="search-box w-auto d-flex justify-content-between gap-2 align-items-center fw-bolder">
                            <div className='filter-search d-flex justify-content-between flex-grow-1'>
                                <div className='flex-grow-1 divider-border'>
                                    <p className='explore-search-text' >Where</p>
                                    {/* <p className='search-box-text-lite'>Search destinations</p> */}
                                    <input type="text" name="" id="" placeholder='Search destinations' />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check in</p>
                                    <input type="text" placeholder="Add date"
                                        // onChange={(e) => console.log(e.target.value)}
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check out</p>
                                    <input type="text" placeholder="Add date"
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                <div className='flex-grow-1'>
                                    <p className='explore-search-text' >Who</p>
                                    <input type="text" name="" id="" placeholder='Add guests' />
                                </div>
                            </div>
                            <button className='btn repeate-btn dark-btn'>Search</button>
                        </div>
                    </Tab>

                    <Tab eventKey="Both" title="Both">

                        <div className="search-box w-auto d-flex justify-content-between gap-2 align-items-center fw-bolder">
                            <div className='filter-search d-flex justify-content-between flex-grow-1'>
                                <div className='flex-grow-1 divider-border'>
                                    <p className='explore-search-text' >Where</p>
                                    {/* <p className='search-box-text-lite'>Search destinations</p> */}
                                    <input type="text" name="" id="" placeholder='Search destinations' />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check in</p>
                                    <input type="text" placeholder="Add date"
                                        // onChange={(e) => console.log(e.target.value)}
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                <div className='flex-grow-1 divider-border '>
                                    <p className='explore-search-text' >Check out</p>
                                    <input type="text" placeholder="Add date"
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                </div>
                                <div className='flex-grow-1'>
                                    <p className='explore-search-text' >Who</p>
                                    <input type="text" name="" id="" placeholder='Add guests' />
                                </div>
                            </div>
                            <button className='btn repeate-btn dark-btn'>Search</button>
                        </div>

                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default ExploreSearch

import React from 'react'
import axios from "axios";
import { useState } from "react";
import "./helpSection.scss"     

const HelpSection = () => {

    const data ={fullName:"",email:"",phoneNumber:""};
    const [inputData,setinputData]=useState(data)


    const handleData =(e)=>{
            setinputData({...inputData,[e.target.name]:e.target.value})
          }
          const handlesubmit =(e)=>{
                e.preventDefault();
             axios .post ("http://159.223.73.77/stayoften-api-dev/user/api/v1/contact",inputData)
                 .then((response)=>{
                     console.log (response)    
                })
               }
    return (
        <>
            <div className="section help-section  primary-bg-sec d-flex flex-row flex-wrap align-items-center">

                <div className="help-section-left d-flex flex-column gap-4 flex-grow-1 " style={{ maxWidth: '525px' }}>
                    <h1 className='Sec-heading text-center text-md-start' >Need help getting started?</h1>
                    <p >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                        invidunt ut labore et dolore magna aliquyam erat, sed diam.</p>
                </div>

                <div className=' flex-grow-1' style={{ maxWidth:'302px' }}>
                    <form >
                        <div className="contact-form d-flex flex-column ">
                            <div className='contact-form-row'>
                                <input className='repeated-bold-text' type="text" placeholder='Full Name' name="fullName"value={inputData.fullName} onChange={handleData}  />
                            </div>
                            <div className='contact-form-row'>
                                <input className='repeated-bold-text' type="email" name="email" id="" placeholder='Email address ' value={inputData.email}  onChange={handleData}/>
                            </div>
                            <div className='contact-form-row'>
                                <input className='repeated-bold-text' type="tel" name="phoneNumber" id="" placeholder='Phone Number'  value={inputData.phoneNumber}  onChange={handleData}/>
                            </div>
                            <button className="btn align-self-start" onClick={handlesubmit}  >Contact Us</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default HelpSection




 



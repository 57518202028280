import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import flag from "./images/flag.png";
import Login from './modal/Login';
import axios from 'axios';
import { BASEURL } from '../../Constant';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import slogo from "./images/slogo.png";
import EnterOtp from './modal/EnterOtp';
import { Link } from 'react-router-dom';
import { Close, FavoriteBorderSharp, Menu } from '@mui/icons-material';

const Navbar = () => {
  const [signupmodal, setSignupmodal] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false); //
  // const [signupmodal, setSignupmodal] = useState(false);
  const [mobilemenu, setMobilemenu] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(BASEURL + "/user/api/v1/gmt/instainformation", {
        firstName,
        lastName,
        email,
      });

      setIsRegistered(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(BASEURL + "/user/api/v1/insta/login", {
        email,
      });

      setIsLoggedIn(true);
      console.log(response.data);
      localStorage.setItem("token",response.data.token)
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    alert("You have successfully logged out!");
  };

  return (
    <>
      <div className='nav-parent position-sticky top-0'>
        <div className='navbar layout-size mx-auto d-flex justify-content-between position-relative align-items-center '>
          <div className=' d-flex gap-2 align-items-center'>
            <div className=' d-md-none' >
              {mobilemenu ?
                <Close onClick={() => setMobilemenu(false)} /> :
                <Menu onClick={() => setMobilemenu(true)} />
              }
            </div>
            <div className='logo-box'> <img src={slogo} alt="" srcSet="" /></div>
          </div>

          <div className=' d-flex gap-4'>
            <div className="d-none d-md-flex align-items-center navlink">
              <Link to='/howItWorks'>How it Works</Link>
              <Link to='/frequently-asked-questions'>FAQ</Link>
             
              <Link to='/explore'> Explore</Link>
              <Link to='/wishlist'><FavoriteBorderSharp /> Wishlist</Link>
              <div>
                {isLoggedIn ? (
                  <button className='btn btn-danger' onClick={handleLogout}><Link to='/'>Logout</Link>
                  </button>
                ) : (
                  // Render login and sign up buttons if not logged in
                  <>
                    <button className="btn repeate-btn navBtn" onClick={() => setSignupmodal(true)}>Sign Up/Login</button>
                    {/* <button className="btn repeate-btn navBtn" onClick={() => setLoginmodal(true)}>Login</button> */}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          {mobilemenu && 
            <div>
              <div className="mobile-navlink d-flex d-md-none justify-content-center" style={{ left: !mobilemenu ? "-390px" : "-20px" }}>
                <div className="d-flex flex-column mt-5">
                  <Link to='/howItWorks'>How it Works</Link>
                  <Link to='/frequently-asked-questions'>FAQ</Link>
                  <Link to='/explore'> Explore</Link>
                  <Link to='/wishlist'><FavoriteBorderSharp /> Wishlist</Link>
                  <div>
                    {isLoggedIn ? (
                      <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
                    ) : (
                      // Render login and sign up buttons if not logged in
                      <>
                        <button className="btn repeate-btn navBtn" onClick={() => setSignupmodal(true)}>Sign Up</button>
                        <button className="btn repeate-btn navBtn" onClick={() => setLoginmodal(true)}>Login</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {/* Sign up modal */}
      <div className="sighup-modal d-flex justify-content-center">
        <Modal
          className='center-modal'
          show={signupmodal}
          onHide={() => setSignupmodal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex flex-column gap-4 modal-form">
              <div className='form-heading'>
                <p>Create an Account</p>
                <h3>Welcome Home.</h3>
              </div>
              <div>
                {isRegistered ? (
                  <h2>Thank you for registering!</h2>
                ) : (
                  <form onSubmit={handleSignUp}>
                    <div className="d-flex flex-column form-body">
                      <div className="form-row">
                        <p>First Name</p>
                        <input
                          type="text"
                          value={firstName}
                          onChange={handleFirstNameChange}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <p>Last Name</p>
                        <input
                          type="text"
                          value={lastName}
                          onChange={handleLastNameChange}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <p>Email Address</p>
                        <input
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </div>
                      <button type="submit" className="btn">
                        <p>Sign Up</p>
                      </button>
                    </div>
                  </form>
                )}
                {isLoggedIn ? (
                  
              <div>
              <h2>You are now logged in!</h2>
              <button onClick={handleLogout} className="btn">
                <p>Logout</p>
              </button>
            </div>
          ) : (
            <form onSubmit={handleLogin}>
              <div className="d-flex flex-column form-body">
                <div className="form-row">
                  <p>Email Address</p>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <button type="submit" className="btn">
                  <p>Login</p>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal.Body>
  </Modal>
</div>
</>
);
};

export default Navbar;

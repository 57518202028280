import React from 'react'
import "./footer.scss"
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <div className="layout-size d-flex flex-column section footer position-relative">

                <div className="d-flex justify-content-evenly justify-content-md-between flex-wrap gap-3">
                    <div className=' d-flex flex-column gap-4' style={{ maxWidth: '430px' }}>
                        <h1 className='Sec-heading ' style={{ letterSpacing:'0px' }} >Join a travel revolution.</h1>
                        <div className='d-flex social-media justify-content-center justify-content-md-start'>
                            <Link to="/"><InstagramIcon /></Link>
                            <Link to="/"><LinkedInIcon /></Link>
                            <Link to="/"> <TwitterIcon /></Link>
                        </div>
                    </div>
                    <div className='footer-link d-flex justify-content-evenly justify-content-md-between'>
                        <div className='first-block d-flex flex-column gap-0 gap-sm-3'>
                            <Link to="/">About</Link>
                            <Link to="/">Careers</Link>
                            <Link to="/">Contact</Link>
                        </div>
                        <div className='second-block d-flex flex-column gap-0 gap-sm-3'>
                            <Link to="/">Terms & Conditions</Link>
                            <Link to="/">Privacy Policy</Link>
                        </div>

                    </div>
                </div>

                <div className=' d-flex flex-column' style={{ gap: '29.12px' }}>
                    <div className="interst-sec d-flex justify-content-between align-items-center flex-wrap">
                        <h4>Show interest</h4>
                        <div className='contact-sec  d-flex gap-1 flex-wrap flex-md-nowrap'>
                            <input type="text" name="" id="" placeholder='David Phillip Rogers' />
                            <input type="number" name="" id="contact" placeholder='Contact Number' />
                            <button className="btn  mx-auto">Submit</button>
                        </div>
                    </div>

                    <p className='copyright-text'>Copyright © 2022 StayOften™. All including IP, digital, image and content rights reserved.</p>
                </div>
            </div>
        </>
    )
}

export default Footer

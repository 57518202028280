import React, { useState } from 'react'
import Questions from './Questions/Questions'
import "./Faques.scss"
import { NavLink } from 'react-router-dom'
import Footer from '../Footer/Footer'

const categories = ["Category A", "Category B", "Category C", "Category D"]
const FAques = () => {
    const [active, setActive] = useState()
    let setposition = (i) => {
        // console.log(i)
        let Faqsection = document.getElementsByClassName("faq-accordian")
        for (let index = 0; index < Faqsection.length; index++) {
            Faqsection[index].classList.remove("active-faq")
        }
        Faqsection[i].classList.add("active-faq")
        Faqsection[0].classList.remove("active-faq")
    }
    return (
        <>
            <div className='layout-size'>
                <div className="section  primary-bg-sec faq-main-page">
                    <div className="d-flex gap-3 mt-4">
                        <div className="faq-navigation-links d-flex flex-column stick-faq-links">
                            {categories?.map((category, i) =>
                                <a className='pointer faq-category-text' key={i}
                                    onClick={() => setposition(i)}
                                    href={`#category${i}`} >{category} Link</a>
                            )}
                        </div>
                        <div className="category-wise-faq d-flex flex-column position-relative "
                        id='faq-sec-container'
                            style={{ left: '325px' }}>
                            <div className='category-wise-faq-heading d-flex flex-column gap-3'>
                                <p>FAQ</p>
                                <h2>Your questions, answered.</h2>
                            </div>
                            <div className='scroll-container d-flex flex-column'>
                                {categories?.map((category, i) =>
                                    <div className='faq-accordian' key={`cat${i}`} id={`category${i}`}>
                                        <div className='Faq-category'>
                                            <p className='faq-category-text'>{category}</p>
                                        </div>
                                        <Questions />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />



            {/* <div className='layout-size d-none'>
                <div className="section   primary-bg-sec primary-text-color" style={{ gap: '40px' }}>
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                            <div className='category-wise-faq-heading d-flex flex-column gap-2'>
                                <p>FAQ</p>
                                <h2>Your questions, answered.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row category-wise-faq">
                        <div className="col-sm-3 pt-3 d-flex flex-column gap-3">
                            {categories?.map((category, i) =>
                                <>
                                    <p className='pointer faq-category-text' key={i} onClick={() => setActive(category)}>{category} Link</p>
                                    <a className='pointer faq-category-text' key={i} href={`#category${i}`} >{category} Link</a>
                                </>
                            )}
                        </div>
                        <div className="col-sm-8 d-flex flex-column  gap-4">
                            <p className='faq-category-text'>{active}</p>
                            <Questions />
                            {categories?.map((category, i) =>
                                <div className='faq-accordian ' key={i} id={`category${i}`}>
                                    <div className='Faq-category'>
                                        <p className='faq-category-text'>{category}</p>
                                    </div>
                                    <Questions />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default FAques

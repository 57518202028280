import { Star } from '@mui/icons-material'
import React from 'react'
import { Cards } from '../../../Homepage/components/Testimonials/Testimonials'
import "./Reviews.scss"

const Reviews = () => {
    return (
        <>
            <div className="section">
                <div className="review-secction d-flex flex-column ">
                    <div className="review-secction-top primary-text-color d-flex flex-column gap-1 ">
                        <h3 className='property-review-heading'>The Provençal Mas</h3>
                        <div className=' d-flex align-items-center' style={{ gap:'29px' }}>
                            <p><Star />Rated 4.1 / 5 </p>
                            <div className="review-divider"></div>
                            <p> 5 Reviews</p>
                        </div>

                    </div>

                    <div className="reviews-card-container detail-description d-flex flex-wrap ">

                        {(Cards.slice(0, 6)).map((person, i) =>
                            <div className='reviews-card small-text d-flex flex-column gap-2 ' key={i} >
                                <div className="d-flex gap-3 align-items-center">
                                    <div className="prof-img">
                                        <img src={person.img} alt="dd" srcSet="" />
                                    </div>
                                    <div>
                                        <p className='small-text person-name'>{person.name}</p>
                                        <p className='small-text'>{person.designation}</p>
                                    </div>
                                </div>
                                <p>{person.message}</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BASEURL, headers } from '../../Constant'
import Footer from '../Footer/Footer'

import Details from './components/Details/Details'
import Location from './components/location/Location'
import Reviews from './components/Reviews/Reviews'
import PropertyPrice from './components/Details/components/PropertyPrice/PropertyPrice'

const PropertyDetails = () => {
  const params = useParams();
  const [property, setproperty] = useState({})

  const getpropertyDetails = () => {
    axios.get(`${BASEURL}/user/api/v1/gmt/propertyview/${params.id}`, { headers }).then((resp) => {
      if (resp.status == 200) {
        setproperty(resp.data.data[0]);
        console.log(resp.data.data[0], "resposs")
      }
    })
  }
  useEffect(() => {
    getpropertyDetails();
  }, [])

  return (
    <>
      {console.log(property, "propertypropertyproperty")}
      <div className='layout-size'>
        <Details property={property} />
        <Reviews property={property} />
        <Location property={property} />
        {/* <PropertyPrice property={property} /> */}
        <Footer />

      </div>
    </>
  )
}

export default PropertyDetails

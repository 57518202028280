import React, { useState, useRef, useEffect } from 'react'
import "./SaveEarn.scss"




const SaveEarn = () => {
    const [range, setRange] = useState(20)
    const [step, setStep] = useState(0);
    const ref = useRef(null);

    let handlevalue = (e) => {
        setRange(e.target.value)
        // console.log(range)
    }

    useEffect(() => {
        const rangeLinePadding = 0;
        const calcStep = (ref.current.offsetWidth - rangeLinePadding) / ref.current.max;
        setStep(calcStep);
    }, []);
    return (
        <>
            <div className="section gap-4 primary-bg-sec">
                <div className="save-sec gap-5 d-flex flex-column">
                    <h1 className='Secondary-heading' >Save & earn.</h1>


                    <div className="d-flex flex-wrap justify-content-evenly justify-content-md-between gap-2">

                        <div className='save-left d-flex flex-column position-relative '>

                            <div className="d-flex flex-column gap-4 position-relative">

                                <h4 className='second-heading'>Number of holiday nights I want per year</h4>
                                <div className="slider position-relative d-flex flex-column">
                                    <input type="range"
                                        min="0" max="50"
                                        className="progress position-relative"
                                        defaultValue={range}
                                        onChange={handlevalue}
                                        id="rangeip"
                                        ref={ref}
                                    />
                                    <label
                                        className='range-lable d-flex justify-content-center align-items-center'
                                        htmlFor="rangeip"
                                        style={{
                                            transform: `translateX(${range * step}px)`,
                                        }}>
                                        <span> {range} </span>
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <h4 className='second-heading'>Number of property shares I own</h4>
                                <div className='save-btn-grp d-flex justify-content-evenly justify-content-md-between gap-2 flex-wrap'>
                                    <button className="btn save-btn active-button">1</button>
                                    <button className="btn  save-btn active-button">2</button>
                                    <button className="btn save-btn">3</button>
                                    <button className="btn save-btn">4</button>
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 flex-wrap">
                                <h4 className='second-heading'>Number of years of savings</h4>
                                <div className='save-btn-grp d-flex gap-1 flex-wrap justify-content-evenly justify-content-md-between'>
                                    <button className="btn small-btn active-small-btn">1</button>
                                    <button className="btn small-btn active-small-btn">2</button>
                                    <button className="btn small-btn active-small-btn">3</button>
                                    <button className="btn small-btn active-small-btn">4</button>
                                    <button className="btn small-btn active-small-btn">5</button>
                                    <button className="btn small-btn active-small-btn">6</button>
                                    <button className="btn small-btn active-small-btn">7</button>
                                    <button className="btn small-btn">8</button>
                                    <button className="btn small-btn">9</button>
                                    <button className="btn small-btn">10</button>
                                </div>
                            </div>
                        </div>

                        <div className="save-divider"></div>

                        <div className='save-right d-flex flex-column'>
                            <div className="d-flex flex-column">
                                <h4 className='second-heading'>Option 1: Traditional holiday home rental.</h4>
                                <p className='repeated-text'>Rent a house for <b>30 nights</b> in an equivalent holiday home, costing <b>₹00,000</b> /year*.</p>
                            </div>
                            <div className="d-flex flex-column">
                                <h4 className='second-heading'>Option 2: Use StayOften, profit ₹0000 /year.</h4>
                                <p>I buy 2 share(s) of a StayOften property, giving me access to 44 nights /year with ownership costs of ₹0000 /year. The remaining 14 nights per year I can rent out generating a rental income of ₹0000 /year. My profit: ₹0000</p>
                            </div>
                            <div className="d-flex flex-column">
                                <h4 className='second-heading'>Option 2: Use StayOften, save ₹0000 over 7 years.</h4>
                                <p>By buying with StayOften, at the end of year 7, I will have saved a total of ₹0000 compared to renting a holiday home and a potential capital gain of ₹0000** if I resell, i.e. total savings of ₹0000</p>
                            </div>
                        </div>


                    </div>

                </div>

                <div className='terms-msg'>
                    <p>We consider that renting an Airbnb house of the same standard as this StayOften house costs ₹0000/night </p>
                    <p>** Assuming an annual real estate appreciation of 4% per year and the notary fees paid at the time of acquisition (7%) explaining a negative amount after only 1 year of ownership.</p>
                </div>
            </div>
        </>
    )
}

export default SaveEarn

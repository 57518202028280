import React from 'react'
import Footer from '../Footer/Footer'
import HelpSection from './component/helpSection/HelpSection'
import MainSection from './component/mainsec/MainSection'
import Topsec from './component/top/Topsec'

const Explore = () => {
    return (
        <>
            <div className='layout-size'>
                <Topsec />
                <MainSection />
                <HelpSection />
                <Footer />
            </div>
        </>
    )
}

export default Explore

import React from 'react'
import SortIcon from '@mui/icons-material/Sort';
import "./mainsection.scss"
import property from "./image/property.jpg"
import FilterCard from './card/FilterCard';
import { Link } from 'react-router-dom';

const MainSection = () => {

    return (
        <>
            <div className="d-flex flex-column">
                {/* sort bar  */}
                <div className="sort-bar primary-text-color d-flex justify-content-between align-items-center py-3 ">
                    <p className='small-text '>Showing 15 results: <Link className='small-text ' to="/">Clear Filters</Link></p>
                    
                    <p className='repeated-bold-text'><SortIcon />  Sort</p>
                </div>

                {/* Feed section */}
                <div className="filter-card-container d-flex justify-content-evenly align-items-center flex-wrap ">
                    <FilterCard />

                  

                    {/* <Link  to={"/CheckOut"}> Click </Link> */}

                   

                </div>
            </div>
        </>
    )
}

export default MainSection

import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import "./stepmodal.scss"
import Slider from "react-slick";
import Step1 from "./image/Step1.svg"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const Slidersettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

const Stepmodal = (props) => {
  // const [modalShow, setModalShow] = useState(true);
  const scrollRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === 'left') {
      current.scrollLeft -= 800;
    } else {
      current.scrollLeft += 800;
    }
  };
  const { stepmodalshow, setStepmodalshow } = props

  return (

    <>
      <div className="step-modal-parent">
        <Modal
          className='center-modal'
          show={stepmodalshow}
          onHide={() => setStepmodalshow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className='step-modal-body position-relative'>

            {/* <Slider {...Slidersettings}> */}
            <div className=" position-relative step-slider-body">

              <div className="step-slider hide-scrollbar position-relative" ref={scrollRef}>

                <div className="step-modal d-flex flex-wrap  flex-md-nowrap  position-relative">
                  <div className='step-modal-left  d-flex gap-4 flex-column flex-grow-1 justify-content-center'>
                    <h1 className=' text-center text-md-start'>Step 01.</h1>
                    <p className='small-text text-center text-md-start'>Years seed fruit you. Divided morning sea day Set earth. Grass without cattle.
                      Spirit heaven. Also I grass give fowl wherein cattle spirit whales rule cattle.
                      Earth fowl given own you are.</p>
                  </div>
                  <div className='step-modal-right flex-grow-1 '>
                    <img src={Step1} alt="" srcSet="" />
                  </div>
                  <div className="modal-skip-btn center position-absolute pointer">
                    <p>Skip <ArrowForwardIcon style={{ width: '15px' }} /></p>
                  </div>
                </div>

                <div className="step-modal d-flex flex-wrap flex-column h-100  flex-md-nowrap justify-content-center ">
                  <h1 className=' text-center text-nowrap'>Ready to get started?</h1>
                  <p className='small-text text-center'>Years seed fruit you. Divided morning sea day Set earth. Grass without cattle.
                    Spirit heaven. Also I grass give fowl wherein cattle spirit whales rule cattle.
                    Earth fowl given own you are.</p>
                  <button className="btn repeate-btn">Proceed</button>
                </div>
                {/* </Slider> */}


              </div>
              <div className="arrow left-arrow pointer center position-absolute"
                onClick={() => scroll('left')}>
                <ArrowBackIcon />
              </div>
              <div className="arrow right-arrow pointer center position-absolute"
                onClick={() => scroll('right')}>
                <ArrowForwardIcon />
              </div>
            </div>
          </Modal.Body>

          {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
        </Modal>
      </div>
    </>
  )
}

export default Stepmodal

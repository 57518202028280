import React from 'react';
import { useParams } from 'react-router-dom';
import { BASEURL, headers } from '../../../Constant';
import { useState, useEffect } from 'react';
import axios from "axios"
import { Card } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';



const WishcardsView = () => {
  const param = useParams();
  const [url, seturl] = useState("http://159.223.73.77/")
  const [data, setdata] = useState([])
  const [loading, setLoading] = useState(true);

  const propertyview = () => {
    setLoading(true); // Set loading to true

    axios
      .get(BASEURL + `/user/api/v1/gmt/propertyview/${param.id}`, { headers })
      .then((response) => {
        console.log(response.data.data, "responsings");
        setdata(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request is completed
      });
  }

  useEffect(() => {
    propertyview();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <center>
            <h3 className="mediam-heading">Your Wishlist Detail</h3>
          </center>
          <br />
          <br />

          <div className='container'>
            {data.map((item, index) => (
              <div className='row' key={index}>
                <div className='col-md-4 text-center'>
                  <br />
                  <p>Main Image</p>
                  <img
                    src={url + item.mainImagePath[0].split('/public')[1]}
                    alt=''
                    srcSet=''
                    className='img-fluid'
                  />
                </div>
                <div className='col-md-4 text-center'>
                  <br />
                  <p>Floor Image</p>
                  <img
                    src={url + item.floorImagePath[0].split('/public')[1]}
                    alt=''
                    srcSet=''
                    className='img-fluid'
                  />
                </div>
                <div className='col-md-4 text-center'>
                  <br />
                  <p>Other Image</p>
                  <img
                    src={url + item.otherImagePath[0].split('/public')[1]}
                    alt=''
                    srcSet=''
                    className='img-fluid'
                  />
                </div>
              </div>
            ))}
            <Table>
              <br />
              <br />
              <br />
              <tbody>
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>Property Name:</td>
                      <td>{item.propertyName}</td>
                    </tr>

                    <tr>
                      <td>Address Line1:</td>
                      <td>{item.addressLine1}</td>
                    </tr>

                    <tr>
                      <td>Address Line2:</td>
                      <td>{item.addressLine2}</td>
                    </tr>

                    <tr>
                      <td>city:</td>
                      <td>{item.city[0].city}</td>
                    </tr>

                    <tr>
                      <td>Min Follower Count:</td>
                      <td>{item.compaing.MinFollowerCount}</td>
                    </tr>

                    <tr>
                      <td>Allow Per Day Booking:</td>
                      <td>{item.compaing.allowPerDayBooking}</td>
                    </tr>

                                        <tr>
                      <td>Discount Of Bookings:</td>
                      <td>{item.compaing.discountOfBookings}</td>
                    </tr>

                    <tr>
                      <td>Start Date:</td>
                      <td>{moment(item.compaing.startDate).format('DD-MM-YYYY')}</td>
                    </tr>

                    <tr>
                      <td>End Date:</td>
                      <td>{moment(item.compaing.endDate).format('DD-MM-YYYY')}</td>
                    </tr>

                    <tr>
                      <td>Total Camping Booking Allowed:</td>
                      <td>{item.compaing.totalCampingBookingAllowed}</td>
                    </tr>

                    <tr>
                      <td>Video Url:</td>
                      <td>{item.videoUrl}</td>
                    </tr>

                    <tr>
                      <td>Property Per Night:</td>
                      <td>{item.propertyPerNight}</td>
                    </tr>

                    <tr>
                      <td>Property Type Name:</td>
                      <td>{item.propertyType[0].propertyTypeName}</td>
                    </tr>

                    <tr>
                      <td>State:</td>
                      <td>{item.state[0].state}</td>
                    </tr>

                    <tr>
                      <td>Property Description:</td>
                      <td>{item.propertyDescription}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  )
}

export default WishcardsView;


import React, { useEffect, useState } from 'react'
import property from "./image/property.jpg"
import Slider from "react-slick";
import { Slidersettings } from '../../../../Homepage/stepmodal/Stepmodal';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from 'axios';
import { BASEURL, headers } from '../../../../../Constant';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom'




const FilterCard = () => {
    const [propertyList, setpropertyList] = useState([]);
    const [url, seturl] = useState("http://159.223.73.77/")
    const [propertyAmenity, setpropertyAmenity] = useState([]);
    const [Amenity, setAmenity] = useState();
       const param = useParams();

    useEffect(() => {
        axios.post(BASEURL + "/user/api/v1/gmt/propertylist?limit=10&page=1&sort=propertyName", "", { headers }).then((resp) => {
            console.log(resp, "......................");
            setpropertyList(resp.data.data[0].propertyData);
        })
    }, [])

 
 
    const handleFavoriteClick = (id) => {
      axios
        .get(`${BASEURL}/user/api/v1/whishlist/property/${id}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Property added to wishlist');
            
            const updatedPropertyList = propertyList.map((property) => {
              if (property._id === id) {
                return {
                  ...property,
                  isFavourite: !property.isFavourite 
                };
              }
              return property;
            });
            setpropertyList(updatedPropertyList); 
          } else {
            console.error('Failed to add property to wishlist');
          }
        })
        .catch((error) => {
          console.error('Failed to add property to wishlist', error);
        });
    };
    


    return (
        <>
           {propertyList.length > 0 ?
  propertyList.map((property) => (
    <div className="filter-card primary-bg-sec d-flex flex-column position-relative">
      {/* property images */}
      <div className="favarite position-absolute d-flex justify-content-center align-items-center">
      <FavoriteBorderIcon
                style={{ width: '70%', color: property.isFavourite ? 'inherit' : 'red' }}
                onClick={() => handleFavoriteClick(property._id)}
              />
      </div>
      <div className="filter-card-img">
        <Slider {...Slidersettings}>
          <img src={url + property.mainImagePath[0].split("/public")[1]} alt="" srcSet="" />
          <img src={url + property.floorImagePath[0].split("/public")[1]} alt="" srcSet="" />
          <img src={url + property.otherImagePath[0].split("/public")[1]} alt="" srcSet="" />
        </Slider>
      </div>

      {/* property description */}
      <div className="filter-card-description d-flex flex-column">
        <Link to={"/property-details/" + property._id}>
          <div className='filter-property-detail d-flex flex-column pb-2 gap-2'>
            <h2>{property.propertyName.length > 25 ? `${property.propertyName.slice(0, 25)}...` : property.propertyName}</h2>
            <p>{property.country}, {property.state[0].state}, {property.city[0].city} | {property.result ? property.result.map((a) => (<span>{a.value == true ? a.value : <CheckIcon />} {a.name} |</span>)) : ""} · {property.squarePerMeter}M<sup>2</sup></p>
          </div>
        </Link>

        <div className="filter-property-price d-flex justify-content-between">
          <div>
            <p>Stay per night</p>
            <h3>{property.propertyPerNight}</h3>
          </div>
          <div>
            <p>Own a share</p>
            <h3>{property.perSharePrice}</h3>
          </div>
        </div>
      </div>
    </div>
  )) : ""}

        </>
    )
}

export default FilterCard

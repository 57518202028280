import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"

import Explore from './component/Explore/Explore'
import About from './component/About'
import Dummy from './component/Dummy'
import Homepage from './component/Homepage/Homepage'
import HowWorks from './component/howWorks/HowWorks'
import Page404 from './component/Page404'
import Home from './component/Home'
import PropertyDetails from './component/property-details/PropertyDetails'
import Wishlist from './component/wishlist/Wishlist'
import FAques from './component/FrequentlyAsksQue/FAques'
import WishcardsView from './component/wishlist/card/WishcardsView'
import CheckOutPage from './component/CheckoutPage/CheckOutPage'

export default function Routing() {
    const [token, settoken] = useState("")
    const routes = [
        { url: "/", component: Homepage },
        { url: "howItWorks", component: HowWorks },
        { url: "explore", component: Explore },
        { url: "property-details", component: PropertyDetails },
        { url: "wishlist", component: Wishlist },
        { url: "frequently-asked-questions", component: FAques },
        { url: "*", component: Page404 },
        { url: "property-details/:id", component: PropertyDetails },
        { url: "Wishcards-View/:id", component: WishcardsView },
        { url: "CheckOut", component: CheckOutPage },

    ]
    
    const mainRoutes = [
        {url: "/" , component:Home},
        { url: "dummy", component: Dummy },
        { url: "*", component: Page404 },
        { url: "about", component: About },
        { url: "explore", component: Explore },
        { url: "property-details/:id", component: PropertyDetails },
        { url: "/", component: Homepage },
        { url: "howItWorks", component: HowWorks },
        { url: "explore", component: Explore },
        { url: "property-details", component: PropertyDetails },
        { url: "wishlist", component: Wishlist },
        { url: "frequently-asked-questions", component: FAques },
        { url: "*", component: Page404 },
        { url: "property-details/:id", component: PropertyDetails },
        { url: "Wishcards-View/:id", component: WishcardsView },
        { url: "CheckOut", component: CheckOutPage },


    ]
    
    useEffect(() => {
        settoken(localStorage.getItem('usertoken'))
    }, [token])

    if (token!=null) {
        return (
            <Routes>
             {mainRoutes.map((data, i) => (
                    <Route path={`/${data.url}`} key={i} element={<data.component />} />
                ))}
            </Routes>
        )
    } else {
        return(
            <Routes>
                {routes.map((data, i) => (
                    <Route path={`/${data.url}`} key={i} element={<data.component />} />
                ))}
            </Routes>
            )
    }
}

import React from 'react'
import "./wishlist.scss"
import Wishcards from './card/Wishcards'
import Footer from '../Footer/Footer'

const Wishlist = () => {
    return (
        <>
            <div className='layout-size'>
                <div className="section primary-text-color gap-4">
                    <h3 className="mediam-heading">Your Wishlist</h3>
                    <div className="wishlist-card-container d-flex flex-wrap justify-content-evenly ">
                        <Wishcards />
                      
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Wishlist

import React, { useState } from 'react'
import "./property.scss"
// import property from "./image/property.jpg"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PropertyImages = (props) => {
    const [url, seturl] = useState("http://159.223.73.77/")
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
       

            <div className="d-flex justify-content-center flex-wrap">
                <div className="row px-0">

                <div className='container'>
  <div className='row text-center'>
    <div className='col-md-4 col-sm-12'>
      <img style={{ width: "80%" }} src={props.property.mainImagePath ? url + props.property.mainImagePath[0].split("/public")[1] : ""} alt="" srcSet="" /><br />
      Main Image
    </div>
    <div className='col-md-4 col-sm-12'>
      <img style={{ width: "80%" }} src={props.property.floorImagePath ? url + props.property.floorImagePath[0].split("/public")[1] : ""} alt="Stayoften" srcSet="" /><br />
      Floor Image
    </div>
    <div className='col-md-4 col-sm-12'>
      <img style={{ width: "80%" }} src={props.property.otherImagePath ? url + props.property.otherImagePath[0].split("/public")[1] : ""} alt="Stayoften" srcSet="" /><br />
      Other Image
    </div>
  </div>
</div>


                   
                </div>
            </div>

            <br/>  <br/>

            <Modal
                className='center-modal'
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className=' mx-auto'>
                        <p> All Property Images</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='see-more-img-grid hide-scrollbar'>
                    <div className="row px-0">
                        <div className="row  px-0 property-img-grid justify-content-around justify-content-lg-start">
                            {props.property.otherImagePath ?
                                ((props.property.otherImagePath)).map((img, i) => (
                                    <div className=" col-sm-2 pb-2 px-1" key={`property-imn${i}`}>
                                        <img src={url + img.split("/public")[1]} alt="" srcSet="" />
                                    </div>
                                ))
                                : ""}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PropertyImages

import React, { useEffect, useState } from 'react'
import "./PropertyPrice.scss"
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { headers } from '../../../../../../Constant';
import { useParams } from 'react-router-dom';
import { BASEURL } from '../../../../../../Constant';



const PropertyPrice = (props) => {

    // localStorage.getItem("token")

    console.log(props, "propspropsprops")
    const params = useParams();
    const [activebtn, setactivebtn] = useState(true)
    const [formData, setFormData] = useState({});
    const [sharePrice, setsharePrice] = useState("");
    const availableShares = () => {
        axios.get(BASEURL + `/user/api/v1/availableshare/${params.id}`).then((resp) => {
            setsharePrice(resp.data[0].totalAssignedShare);
            console.log(resp.data[0].totalAssignedShare)
        })
    }
    useEffect(() => {
        availableShares();
        console.log(props)
        let obj = {};
        obj.propertyId = props.property ? props?.property?.compaing?.propertyId : ""
        obj.campaingId = props.property ? props?.property?.compaing?._id : ""
        console.log(obj,"OBJ")
        setFormData(obj);

    }, [])

    useEffect(() => {
        axios.post(BASEURL + "/user/api/v1/gmt/propertylist?limit=10&page=1&sort=propertyName", "", { headers }).then((resp) => {
            console.log(resp.data.data[0].propertyData, "mynreres");
            // setpropertyList(resp.data.data[0].propertyData);
        })
    }, [])


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
      
    //     const token = localStorage.getItem("token");
      
    //     try {
    //       const response = await axios.post(BASEURL + "/user/api/v1/booking-request", formData, {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       });
      
       
    //       alert("Successfully submitted!");
      
         
    //       const button = e.target;
    //       button.parentNode.removeChild(button);
      
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
      
      
  
      const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const payload = {
            propertyId: props.property ? props?.property?.compaing?.propertyId : "",
            campaingId: props.property ? props?.property?.compaing?._id : ""
         
        };
    
        try {
          const response = await axios.post(BASEURL + "/user/api/v1/booking-request", payload, {
                  headers: {
                     Authorization: `Bearer ${token}`,
                  },
               });;
          console.log(response.data);
              alert("Success!"); 
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <>
            <div className="property-price-card repeated-text d-flex  flex-column">
              

                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home"
                        title={<><h4>Stay per night</h4> <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight : ""}</p></>}
                        className=''>
                        <div className='pricing-tab d-flex flex-column gap-3'>

                            <div className='check-in-out'>
                                <div className="d-flex check-in-out-date flex-wrap">
                                    <div className=' flex-grow-1 check-in-out-divider'>
                                        <p className='small-bold-text-line'>Check In</p>
                                        <input type="date" />
                                    </div>
                                    <div className=' flex-grow-1'>
                                        <p className='small-bold-text-line'>Check In</p>
                                        <input type="date" />
                                    </div>
                                </div>
                                <div className='gust-no'>
                                    <p className='small-bold-text-line'>Guests</p>
                                    <Form.Select aria-label="Default select example" className='focus-no-outline'>
                                        <option>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </div>
                            </div>

                            <div className='price-card-pricing gap-2 d-flex flex-column'>
                                <div className='sub-total d-flex flex-column gap-2'>
                                    <div className="d-flex justify-content-between pb-2">
                                        <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight : ""} x 7 nights</p>
                                        <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight * 7 : ""}</p>
                                    </div>

                                    {/* <div className="d-flex justify-content-between">
                                        <p>₹20,000 x 7 nights</p>
                                        <p>₹140,000</p>
                                    </div>
                                    <div className="d-flex justify-content-between pb-2">
                                        <p>₹20,000 x 7 nights</p>
                                        <p>₹140,000</p>
                                    </div> */}

                                </div>
                                <div className="total-price d-flex justify-content-between  ">
                                    <p>Total</p>
                                    <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight * 7 : ""}</p>
                                </div>
                            </div>

                            {/* button */}
                            <button className="btn blue-btn">Book Using Owner Wallet</button>
                        </div>
                    </Tab>
                    <Tab eventKey="profile"
                        title={<><h4>Stay per night</h4> <p>₹{props.property.perSharePrice ? props.property.perSharePrice : ""}</p></>}
                    >
                        <div className='pricing-tab  d-flex flex-column gap-3'>
                            <div className='check-in-out gust-no gap-2 d-flex flex-column'>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className='small-bold-text-line'>Shares Available</p>
                                        <p>{sharePrice ? 10 - sharePrice : 10} of 10</p>
                                    </div>
                                    <div className=' text-end'>
                                        <p className='small-bold-text-line'>Subscription Amount (4 yrs)</p>
                                        <p>₹{props.property.monthlySubscriptionFee ? props.property.monthlySubscriptionFee : ""}</p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className='small-bold-text-line'>Cost per night (10 yrs)*</p>
                                        <p>₹{props.property.propertyPerNight ? props.property.propertyPerNight * 7 : ""}</p>
                                    </div>
                                    <div className=' text-end'>
                                        <p className='small-bold-text-line'>Deposite Amount</p>
                                        <p>₹{props.property.depositAmount ? props.property.depositAmount : ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className='small-bold-text-line'>Benefits of ownership</p>
                                <p className='small-text'>- Lorem ipsum dolor sit amet, consetetur sadipscing elit.</p>
                                <p className='small-text'>- Lorem ipsum dolor sit amet, consetetur sadipscing.</p>
                                <p className='small-text'>- Lorem ipsum dolor sit amet, elit.</p>
                            </div>
                            {/* button */}
                            <button className="btn blue-btn" onClick={handleSubmit}>Show Interest</button>

                            {/* {props.property ? props?.property?.compaing?.propertyId : ""} */}
                        </div>
                    </Tab>
                </Tabs>

                {/* {props.property.compaing._id ? props.property.compaing._id : ""} */}

                {/* check in check out */}
                {/* <div className='check-in-out'>
                    <div className="d-flex check-in-out-date flex-wrap">
                        <div className=' flex-grow-1 check-in-out-divider'>
                            <p className='small-bold-text'>Check In</p>
                            <input type="date" />
                        </div>
                        <div className=' flex-grow-1'>
                            <p className='small-bold-text'>Check In</p>
                            <input type="date" />
                        </div>
                    </div>
                    <div className='gust-no'>
                        <p className='small-bold-text'>Guests</p>
                        <Form.Select aria-label="Default select example" className='focus-no-outline'>
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>
                </div> */}


                {/* Share availables */}
                {/* <div className=' d-flex flex-column gap-3'>
                    <div className='check-in-out gust-no gap-2 d-flex flex-column'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className='small-bold-text'>Shares Available</p>
                                <p>3 of 8</p>
                            </div>
                            <div className=' text-end'>
                                <p className='small-bold-text'>Subscription Amount (4 yrs)</p>
                                <p>₹20,000,000</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div>
                                <p className='small-bold-text'>Cost per night (10 yrs)*</p>
                                <p>₹10,000</p>
                            </div>
                            <div className=' text-end'>
                                <p className='small-bold-text'>Deposite Amount</p>
                                <p>₹20,000,000</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='small-bold-text'>Benefits of ownership</p>
                        <p className='small-text'>- Lorem ipsum dolor sit amet, consetetur sadipscing elit.</p>
                        <p className='small-text'>- Lorem ipsum dolor sit amet, consetetur sadipscing.</p>
                        <p className='small-text'>- Lorem ipsum dolor sit amet, elit.</p>
                    </div>
                    <button className="btn blue-btn">Show Interest</button>
                </div> */}


                {/* pricing section */}
                {/* <div className='price-card-pricing gap-2 d-flex flex-column'>
                    <div className='sub-total d-flex flex-column gap-2'>
                        <div className="d-flex justify-content-between">
                            <p>₹20,000 x 7 nights</p>
                            <p>₹140,000</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>₹20,000 x 7 nights</p>
                            <p>₹140,000</p>
                        </div>
                        <div className="d-flex justify-content-between pb-2">
                            <p>₹20,000 x 7 nights</p>
                            <p>₹140,000</p>
                        </div>

                    </div>
                    <div className="total-price d-flex justify-content-between fw-bold">
                        <p>Total</p>
                        <p>₹140,000</p>
                    </div>
                </div> */}


                {/* button */}
                {/* <button className="btn blue-btn">Book Using Owner Wallet</button> */}

            </div>
        </>
    )
}

export default PropertyPrice

import React from 'react'

const Location = () => {
    return (
        <>
            <div className='location-section section primary-bg-sec gap-3'>
                <div >
                    <h3 className="mediam-heading" style={{ lineHeight: '38px' }}>Location</h3>
                    <p className="repeated-text" style={{ lineHeight: '38px', fontWeight:'600' }} >Location, Country</p>
                </div>
                <div className="map">
                    <iframe style={{ border: 0, width: '100%' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29771.896142408477!2d79.07740264165948!3d21.13300828956048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c172fe26f1d3%3A0xb93dfb1374ff0968!2sVR%20Mall!5e0!3m2!1sen!2sin!4v1672318738589!5m2!1sen!2sin" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}

export default Location

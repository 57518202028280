import React from 'react'
import "./howstart.scss"
import Person from "./image/Person.svg"

const Howstart = (props) => {

    let { heading, primary , gap, setStepmodalshow} = props

    let steps = [
        { heading: "First step", title: 'orem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut L' },
        { heading: "Second step", title: 'orem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut L' },
        { heading: "Third step", title: 'orem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut L' },
        { heading: "Forth step", title: 'orem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut L' },
    ]

    return (
        <>
            <div className={`how-start section ${primary ? "primary-bg-sec" : "secondary-bg-sec"} justify-content-center overflow-hidden`} style={{ gap:`${gap}px`}}>

                <h1 className='Sec-heading ' >{heading}</h1>
                <div className="d-flex justify-content-evenly justify-content-md-between gap-2 flex-wrap">

                    {steps.map((step, i) =>
                        //  single card 
                        <div className={`step-card  ${primary ? "primary-step-card" : "secondry-step-card"} d-flex flex-column position-relative`} key={i}>

                            <div role="button" className={`how-step ${primary ? "bg-light" : "primary-bg-sec"}  d-flex align-items-center justify-content-center`}
                            onClick={()=> setStepmodalshow(true)}
                            >
                                <div className='filter-img-icon'><img src={Person} alt="" srcSet="" /></div>
                            </div>
                            <div>
                                <h3>{step.heading}</h3>
                                <p>{step.title}</p>
                            </div>
                        </div>
                    )}
                    {/* <Onecard /> */}
                </div>
            </div>
        </>
    )
}

export default Howstart

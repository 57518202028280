import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Ques = [
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
    { Header: 'How does StayOften work?', Body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed doLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do " },
]
const Questions = () => {
    return (
        <>
            <Accordion defaultActiveKey="0" className='primary-text-color'>
                {Ques?.map((que, i) =>
                    <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header className='faq-category-small-text'>{que.Header}</Accordion.Header>
                        <Accordion.Body>{que.Body}</Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion>
        </>
    )
}

export default Questions

import React from 'react'
import Footer from '../Footer/Footer'
import Faq from '../Homepage/components/FAQ/Faq'
import Herosec from '../Homepage/components/Herosec/Herosec'
import SaveEarn from '../Homepage/components/Save&earn/SaveEarn'
import HowItWorks from './components/howItWork/HowItWorks'
import SimpleThings from './components/simpleThing/SimpleThings'
import howworks from "./image/howworks.jpg"

const HowWorks = () => {
    return (
        <>
            <Herosec heading="Here’s how we allow you to travel often, at a fraction of the price."
                title="sea takimd tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                bgimg={howworks} textSmall={true}
            />
            <div className='layout-size above-header'>
                <HowItWorks />
                <SimpleThings />
                <SaveEarn />
                <Faq />
                <Footer />

            </div>
        </>
    )
}

export default HowWorks
